import { useState } from "react"

import { NoiseAlertCAUpgradeDisclaimer } from "src/components/MonitoringPresets/MonitoringPresetCards/NoiseAlertCAUpgradeDisclaimer"
import { NoiseAlertsDialog } from "src/components/MonitoringPresets/MonitoringPresetDialogs/NoiseAlertDialog/NoiseAlertsDialog"
import {
  usePostTrackNoiseSettingManagedEvent,
  variantToAlertLevel,
} from "src/data/analytics/queries/settingsAnalyticsQueries"
import { useGetCallAssistActive } from "src/data/callAssist/queries/callAssistQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchOrganization } from "src/data/organizations/queries/organizationQueries"
import { TNoiseAlertVariant } from "src/data/profileSettings/noiseAlertTypes"
import { TNoiseMonitoringPreset } from "src/data/profileSettings/types/monitoringPresetTypes"
import { TTranslateFunction, useTranslate } from "src/i18n/useTranslate"
import { MInfo } from "src/ui/Info/MInfo"
import { MSettingsCard } from "src/ui/MCard/MSettingsCard"
import { MText } from "src/ui/MText"

type PresetSettingCardNoiseAlertProps = {
  presetData: TNoiseMonitoringPreset
  variant: TNoiseAlertVariant
}

export function PresetSettingCardNoiseAlert({
  presetData,
  variant,
}: PresetSettingCardNoiseAlertProps) {
  const { orgId } = useOrganization()
  const { t, langKeys } = useTranslate()

  const [dialogOpen, setDialogOpen] = useState(false)
  const { settings } = useNoiseAlertSettings(presetData.disturbance_monitoring)
  const noticeSettings = settings[variant]

  const { callAssistIsActive } = useGetCallAssistActive()
  const showCallAssistUpgrade = variant === "third_alert" && !callAssistIsActive
  const showCallAssistPill = variant === "third_alert" && callAssistIsActive

  const manageTrackingEvent = usePostTrackNoiseSettingManagedEvent()
  function handleManageClick() {
    manageTrackingEvent.mutate({
      alert_level: variantToAlertLevel[variant],
    })
    setDialogOpen(true)
  }

  const truncateText = (text: string, maxlen: number = 400) =>
    text.length > maxlen ? text.substring(0, maxlen) + "..." : text
  const getTextArray = (text: string) =>
    truncateText(text)
      .split("\n")
      .filter((t) => !!t)
  const smsContentTooltip = getTextArray(noticeSettings.smsContent)
  const autocallContentTooltip = getTextArray(noticeSettings.autocallContent)

  return (
    <MSettingsCard
      title={<NoiseAlertSettingCardTitle variant={variant} />}
      description={<NoiseAlertSettingCardDescription variant={variant} />}
      onManageClick={handleManageClick}
      labeledPills={[
        {
          label: t(langKeys.settings_noise_alerts_sms_title),
          id: "sms",
          isOn: !!noticeSettings.smsEnabled,
          get tooltipData() {
            return this.isOn ? smsContentTooltip : undefined
          },
        },
        {
          label: t(langKeys.settings_noise_alerts_autocall_title),
          id: "autocall",
          isOn: !!noticeSettings.autocallEnabled,
          get tooltipData() {
            return this.isOn ? autocallContentTooltip : undefined
          },
        },
        {
          label: t(langKeys.settings_noise_alerts_flash_and_sound_title),
          id: "flash",
          isOn: !!noticeSettings.flashEnabled,
        },
        {
          label: t(langKeys.call_assist),
          id: "call-assist",
          isOn: settings.callAssistEnabled ?? false,
          hidden: !showCallAssistPill,
        },
      ]}
    >
      {showCallAssistUpgrade && <NoiseAlertCAUpgradeDisclaimer />}
      <NoiseAlertsDialog
        onClose={() => setDialogOpen(false)}
        settings={{
          orgId,
          profileId: presetData.id,
          callAssistEnabled: settings.callAssistEnabled,
          ...noticeSettings,
        }}
        variant={variant}
        open={dialogOpen}
      />
    </MSettingsCard>
  )
}

export function NoiseAlertSettingCardTitle({
  variant,
}: {
  variant: TNoiseAlertVariant
}) {
  const { langKeys, t } = useTranslate()

  function title(variant: TNoiseAlertVariant, t: TTranslateFunction) {
    const titles: Record<TNoiseAlertVariant, string> = {
      first_alert: t(langKeys.settings_noise_alerts_first_alert_title),
      second_alert: t(langKeys.settings_noise_alerts_second_alert_title),
      third_alert: t(langKeys.settings_noise_alerts_third_alert_title),
    }

    return titles[variant]
  }

  return <>{title(variant, t)}</>
}

export function NoiseAlertSettingCardDescription({
  variant,
}: {
  variant: TNoiseAlertVariant
}) {
  const { t, langKeys } = useTranslate()

  function description(variant: TNoiseAlertVariant, t: TTranslateFunction) {
    const descriptions: Record<TNoiseAlertVariant, string> = {
      first_alert: t(langKeys.settings_noise_alerts_first_alert_description),
      second_alert: t(langKeys.settings_noise_alerts_second_alert_description),
      third_alert: t(langKeys.settings_noise_alerts_third_alert_description),
    }

    return descriptions[variant]
  }

  return (
    <>
      {description(variant, t)}{" "}
      {variant === "first_alert" && (
        <MInfo
          wrapperProps={{ style: { display: "inline" } }}
          interactive
          content={
            <>
              <MText variant="bodyS">
                {t(langKeys.settings_noise_alert_first_trigger)}
              </MText>
            </>
          }
        />
      )}
    </>
  )
}

function useNoiseAlertSettings(
  disturbance: TNoiseMonitoringPreset["disturbance_monitoring"]
) {
  // NB: Alert content should be moved to presets at some point, but for now
  // we're fetching it from the old place, the organization.
  const { orgId } = useOrganization()
  const fetchOrg = useFetchOrganization({ id: orgId })
  const org = fetchOrg.data
  const content = {
    first: {
      ...org?.guest_communication?.disturbance_monitoring?.first_notice,
    },
    second: {
      ...org?.guest_communication?.disturbance_monitoring?.second_notice,
    },
    third: {
      ...org?.guest_communication?.disturbance_monitoring?.third_notice,
    },
  }

  const settings = {
    first_alert: {
      smsEnabled: !!disturbance?.first_notice_sms_enabled,
      smsContent: content.first.sms_content ?? "",
      autocallEnabled: !!disturbance?.first_notice_autocall_enabled,
      autocallContent: content.first.autocall_content ?? "",
      flashEnabled: !!disturbance?.first_notice_flash_and_sound,
    },
    second_alert: {
      smsEnabled: !!disturbance?.second_notice_sms_enabled,
      smsContent: content.second.sms_content ?? "",
      autocallEnabled: !!disturbance?.second_notice_autocall_enabled,
      autocallContent: content.second.autocall_content ?? "",
      flashEnabled: !!disturbance?.second_notice_flash_and_sound,
    },
    third_alert: {
      smsEnabled: !!disturbance?.third_notice_sms_enabled,
      smsContent: content.third.sms_content ?? "",
      autocallEnabled: !!disturbance?.third_notice_autocall_enabled,
      autocallContent: content.third.autocall_content ?? "",
      flashEnabled: !!disturbance?.third_notice_flash_and_sound,
    },
    callAssistEnabled: !!disturbance?.call_assist,
  }

  return { settings }
}
